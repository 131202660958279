import React, { Component } from "react";

const boxStyle = {
    margin: '10px'
  , padding: '20px'
  , background: '#ffdd55'
  , width: '10em'
  , display: 'inline-block'
  , verticalAlign: 'top'

};

/** 
 * A box representing one pinLocker distribution
 * @param distributionData - the Distribution Object. needs at least name and ref
 */
class PlDistributionBox extends Component {
  render() {
    let { name, ref, gitlab, documentation } = this.props.distributionData;
    return (
      <div style={{ ...boxStyle }}>
        <h2>{name}</h2>
        <div> Download <a href={ref} download> here </a> </div>
        <div>
          { (gitlab || documentation) &&
            <details>
              <summary>Other Information</summary>
              <ul style={{'padding': 0, 'listStyleType': 'none'}}>
                { documentation &&
                  <li><a href={documentation}>Documentation</a></li>
                }
                { gitlab &&
                  <li><a href={gitlab}>Git Repository</a></li>
                }
              </ul>
            </details>
          }
        </div>
      </div>
    );
  }
}

export { PlDistributionBox };
