/* eslint-disable react/jsx-no-comment-textnodes */
import React, { Component } from 'react';
import { PlDistributionBox } from "./PlDistributionBox"
import './App.css';

 
class App extends Component {
  constructor() {
    super()
    this.state = {distributions: []}
  }

  componentDidMount() {
    const host = window.location.host
    const distributionsURL = process.env.DISTRIBUTIONS_URL || `https://${host}/files/distributions.json`

    fetch(distributionsURL)
        .then(response => response.json())
        .then(response => response.distributions.map(dist => {
          return {...dist, ref: dist.filename ? `/files/${dist.filename}` : dist.url }
        }))
        .then(distributions => this.setState({distributions: distributions}))
  }


  render() {
    return (
      <div className="App">
        <h1>pinLocker.de</h1>
        <div style={{top: '0', position: 'sticky'}}>
          { this.state.distributions &&
            this.state.distributions
              .map( dist => <PlDistributionBox key={dist.name} distributionData={dist}/> )
          }
        </div>
      </div>
    );
  }
}

export default App;
